#wrapper.faq{
	.title_image{
		@include pc(){
			background:url(../images/faq/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
    
    .title_style04{
        margin-bottom: 40px;
    }
}


.faq_nav{
    margin-top: 30px;
}
.faq_box{
    margin-top: 10px;
    border: 1px solid #666666;
    padding:10px;
    position: relative;
    h3{
        padding-left: 47px;
		cursor:pointer;
		line-height:1.3;
        .fa-stack{
            margin-left: -47px;
            margin-right: 5px;
        }
        .fa-square{
            
            //color:#16AA89;
        }
    }
	&:hover{
		background: #fafafa;
		transition:all 0.25s;
		.fa-square{
            transition:all 0.25s;
            color:#16AA89;
        }
	}
	.comment{
		padding-left:42px;
		position: relative;
        min-height: 40px;
		margin-top:5px;
        padding-top: 10px;
        border-top: 1px dotted #666666;
		display:none;
		.fa-square{
			color:#3b78b5;
		}
		&.open{
			display:block;
			span{
				position: absolute;
				top: 10px;
				left: 0;
			}
		}
	}
    
    .category_box{
        display: inline-block;
        padding:5px 2px 4px 2px;
        text-align: center;
        width: 130px;
        border: 1px solid #666;
        border-bottom: 0;
        position: absolute;
        top: -22px;
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
    }
}

