.links_list{
	padding:20px;
	li{
		font-size:16px;
		margin-bottom:5px;
		a{
			color:#222222;
		}
	}
}


.error_wrap{
	padding:50px 0px;
}


/*　お問い合せ先一覧　*/
.contact_list{
	.title_style04 {
		font-size: 24px;
		border-bottom: none;
		border-top: 1px solid #16AA89;
		margin-top: 50px;
		margin-bottom: 30px;
		padding-top: 10px;
	}
	.table_style01{
		margin-bottom: 100px;
		th,td{
			padding: 10px 10px;
		}
	}
}


.tab_list{
	li{
		a{
			text-align: left;
			min-width: 240px;
			position: relative;
			@include sp(){
				width: 100%;
				text-align: center;
			}
			.icon{
				position: absolute;
				right: 30px;
				@include sp(){
					position: static;
					padding-left: 5px;
				}
			}
		}
	}
}