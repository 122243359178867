body#news,
#wrapper.news{
	.title_image{
		@include pc(){
			background:url(../images/news/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
}

.news_archive{
	li{
		border-bottom:1px solid #EEEEEE;
		padding:20px 0px;
		@include clearfix();
		.visual{
			float:left;
			margin-right:20px;
			width:200px;
			@include sp(){
				width:120px;
			}
		}
		.news_date{
			color: #BCA882;
			margin-bottom:10px;
		}
	}
}