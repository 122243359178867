#wrapper.company{
	.title_image{
		@include pc(){
			background:url(../images/company/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
}

.company_img01{
	@include pc(){
		float:right;
		padding:50px 0px 30px 30px;
	}
	@include sp(){
		text-align:center;
	}
    img.img_shadow{
        box-shadow:5px 5px 10px -2px #adadad;
        -moz-box-shadow:5px 5px 10px -2px #adadad;
        -webkit-box-shadow:5px 5px 10px -2px #adadad;
    }
}
.company_position{
	margin-top:20px;
}
.company_name{
	font-size:24px;
    img{
        width:180px;
    }
}

#greeting{
	.text_wrap{
		overflow:hidden;
	}
}

