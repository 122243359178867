#wrapper.attention{
	.title_image{
		@include pc(){
			background:url(../images/attention/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
}
