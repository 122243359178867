body#blog,
body.tax-blog_cat,
#wrapper.blog{
	.title_image{
		@include pc(){
			background:url(../images/blog/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
}


.blog_title{
	margin:30px 0px 20px;
	font-size:28px;
}
.blog_date{
	color:#BCA882;
}

.blog_head{
	margin-bottom:20px;
	@include clearfix();
	.blog_date{
		float:left;
		margin-right:50px;
	}
	.blog_cat{
		overflow:hidden;
	}
}

a.btn_category{
	display: inline-block;
	background-color: #51B39E;
	color: #FFFFFF;
	font-size: 11px;
	text-decoration: none;
	padding: 0px 10px;
	line-height: 20px;
	margin-left: 20px;
	position: relative;
	text-decoration:none;
	transition: 0.3s;
	&:before{
		position: absolute;
		top: 0;
		left: -10px;
		border: 10px solid transparent;
		border-left: 0;
		content: '';
		height: 0;
		width: 0;
		border-right-color: #51B39E;
		transition: 0.3s;
	}
	&:after{
		position: absolute;
		top: 8px;
		left: -2px;
		width: 4px;
		content: '';
		height: 4px;
		background-color: #ffffff;
		border-radius: 50%;
	}
	
	&:hover{
		background-color:#00816F;
		&:before{
			border-right-color:#00816F;
		}
	}
}


/*** social-area ***/
.social-area-syncer{
	margin:30px 0px;
	padding:20px;
	border-top:1px solid #EEEEEE;
	border-bottom:1px solid #EEEEEE;
	.social-button-syncer{
		@include clearfix();
		li{
			float:left;
			margin-right:20px;
			height:30px;
		}
	}
}


/*** link_nav ***/
.link_nav_wrap{
	@include clearfix();
	span{
		a{
			background-color:#51B39E;
			color:#FFFFFF;
			padding:10px 20px;
			text-decoration:none;
			transition: 0.3s;
			&:hover{
				background-color:#00816F;
			}
		}
	}
	@include pc(){
		.left{
			float:left;
			display:inline-block;
			margin-right:10px;
			margin-bottom:10px;
		}
		.right{
			float:right;
			display:inline-block;
			margin-left:10px;
			margin-bottom:10px;
		}
	}
	@include sp(){
		.left,
		.right{
			display:block;
			margin-bottom:10px;
			a{
				text-align:center;
				display:block;
			}
			
		}
	}
}


/*** blog_list ***/
.blog_list{
	//border-top:1px solid #EEEEEE;
	li{
		padding:10px 0px;
		border-bottom:1px solid #EEEEEE;
		@include clearfix();
		.thumbnail{
			float:left;
			margin-right:10px;
		}
		.text_wrap{
			overflow:hidden;
		}
	}
}


 /*** recommend_box ***/
 .recommend_box{
	 margin-top:50px;
	 .blog_list{
		 @include pc(){
			 @include clearfix();
			 margin-left:-20px;
			 li{
				 width:375px;
				 float:left;
				 margin-left:20px;
			 }
		 }
	 }
 }


/** blog_archive ***/
.blog_archive{
	@include clearfix();
	li{
		float:left;
		@include pc(){
			width:calc( ( 99% - 30px ) / 3 );
			margin:0px 5px 20px;
		}
		@include sp(){
			width:calc( ( 100% - 20px ) / 2 );
			margin:0px 5px 20px;
		}
		a{
			text-decoration:none;
		}
		.blog_title{
			font-size:16px;
			margin:10px 0px;
		}
		.text{
			font-size:13px;
		}
	}
}



/**** pageNavi ****/
.pagination_top{
	margin-bottom:30px;
}
.pagination_bottom{
	margin:30px 0px;
}
.pageNaviWrap{
	@include clearfix();
	//padding:0px 10px;
}
.pageNavi{
	@include pc(){
		float:right;
		margin-right:-6px;
	}
	/*padding-top:60px;
	padding-bottom:30px;
	
	@include sp{
		padding-top:20px;
	}*/
}
.pageNavi span,
.pageNavi a{
	/*display:inline-block;
	*display:inline;
	*zoom:1;*/
	display:block;
	float:left;
	text-decoration:none;
	height:32px;
	line-height:32px;
	text-align:center;
	transition:all 0.2s;
}
.pageNavi .inactive,
.pageNavi .current,
.pageNavi .arrow{
	width:32px;
	font-size:100%;
}
.pageNavi a.inactive,
.pageNavi .arrow{
	border:1px solid #E1E1E1;
	background-color:#FFFFFF;
	color:#000000;
}
.pageNavi .current,
.pageNavi .arrow:hover,
.pageNavi .inactive:hover,
.pageNavi .next:hover .text,
.pageNavi .back:hover .text{
	//border:1px solid #555555;
	//background-color:#FFFFFF;
	border:1px solid #51B39E;
	background-color:#51B39E;
	color:#FFFFFF;
}
.pageNavi .text{
	width:47px;
	border:1px solid #DEF1E2;
	background-color:#FFFFFF;
	color:#BFE4C7;
	font-size:71%;
	-webkit-transition:0.5s;
	-moz-transition:0.5s;
	-o-transition:0.5s;
	transition:0.5s;
}
.space{
	color:#000000;
	font-size:84%;
}
.pageNavi .inactive,
.pageNavi .current,
.pageNavi .back .text,
.space,
.arrow{
	margin-right:6px;
}

.blog_editor{
	padding:50px 0px;
	font-size: 14px;
	width: 680px;
	@include sp(){
		width: auto;
	}
	h2{
		border-bottom:1px solid #DDDDDD;
		border-left:5px solid #16AA89;
		padding-left:5px;
		font-size:20px;
		margin-bottom:30px;
		margin-top:60px;
	}
	h3{
		margin-bottom:15px;
		margin-top:30px;
		font-size:16px;
	}
	p{
		margin-bottom:10px;
		line-height:1.8;
	}
	a{
		color:#16AA89;
	}
	ol{
		list-style-type:decimal;
		margin-left:2em;
		margin-bottom:20px;
		li{
			margin-bottom:10px;
		}
	}
	ul{
		list-style-type:disc;
		margin-left:2em;
		margin-bottom:20px;
		li{
			margin-bottom:10px;
		}
	}
	dl{
		margin-bottom:20px;
		dt{
			font-weight:bold;
		}
		dd{
			margin-bottom:10px;
		}
	}
}

.news_editor{
	padding:50px 0px;
	font-size: 14px;
	width: 680px;
	@include sp(){
		width: auto;
	}
	h2{
		/*border-bottom:1px solid #DDDDDD;
		border-left:5px solid #16AA89;
		padding-left:5px;*/
		font-size:20px;
		margin-bottom:10px;
		margin-top:30px;
	}
	h3{
		margin-bottom:10px;
		margin-top:20px;
		font-size:16px;
	}
	p{
		margin-bottom:10px;
		line-height:1.5;
	}
	a{
		color:#16AA89;
	}
	ol{
		list-style-type:decimal;
		margin-left:2em;
		margin-bottom:20px;
		li{
			margin-bottom:10px;
		}
	}
	ul{
		list-style-type:disc;
		margin-left:2em;
		margin-bottom:20px;
		li{
			margin-bottom:10px;
		}
	}
	dl{
		margin-bottom:20px;
		dt{
			font-weight:bold;
		}
		dd{
			margin-bottom:10px;
		}
	}
}





