
#top-slider{
	@include pc(){
		min-width:1170px;
	}
	.sp-buttons{
		background-color:#F4F3EE;
	}
}

.main_nav{
	margin:50px 0px;
	@include sp(){
		margin:20px 0px;
	}
}
#top_merit{
	@include pc(){
		height:390px;
		background:url(../images/top/bg_merit.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-attachment: fixed;
		min-width:1170px;
		color:#FFFFFF;
		.subtitle{
			position:absolute;
			left:10px;
			top:43px;
			font-size:26px;
			font-weight:normal;
		}
		ul{
			li{
				width:400px;
				height:86px;
				margin-bottom:20px;
				.box{
					background-color:rgba(216,216,216,0.07);
					border:1px solid rgba(225,225,225,0.35);
					display:table;
					width:100%;
					height:100%;
					box-sizing:border-box;
					text-decoration:none;
					transition: 0.3s;
					&:hover{
						background-color:rgba(216,216,216,0.4);
					}
					.num{
						display:table-cell;
						width:70px;
						vertical-align:middle;
						text-align:center;
					}
					.text{
						display:table-cell;
						width:250px;
						vertical-align:middle;
						padding-right:10px;
						font-size:20px;
						line-height:1.3;
						color:#FFFFFF;
						text-decoration:none;
					}
					.icon{
						display:table-cell;
						vertical-align:middle;
					}
				}
			}
			&.top_merit01{
				position:absolute;
				left:337px;
				top:43px;
			}
			&.top_merit02{
				position:absolute;
				left:762px;
				top:43px;
			}
		}
	}
	@include sp(){
		background:url(../images/top/bg_merit.jpg) center top no-repeat;
		background-size:cover;
		color:#FFFFFF;
		padding:10px 0px;
		.subtitle{
			text-align:center;
			padding:10px 0px;
			font-size:22px;
		}
		ul{
			li{
				.box{
					background-color:rgba(216,216,216,0.07);
					border-bottom:1px solid rgba(225,225,225,0.35);
					display:table;
					width:100%;
					height:100%;
					box-sizing:border-box;
					padding:10px 0px;
					text-decoration:none;
					.num{
						display:table-cell;
						width:70px;
						vertical-align:middle;
						text-align:center;
					}
					.text{
						display:table-cell;
						//width:250px;
						vertical-align:middle;
						padding-right:10px;
						font-size:16px;
						color:#FFFFFF;
					}
					.icon{
						display:table-cell;
						vertical-align:middle;
						display:none;
					}
				}
			}
			&.top_merit01{
				border-top:1px solid rgba(225,225,225,0.35);
				margin-top:10px;
			}
			&.top_merit02{
				margin-bottom:20px;
			}
		}
	}
}


#top_contact{
	@include pc(){
		margin:50px 0px;
		ul{
			@include clearfix();
			margin:0px 45px;
			li{
				float:left;
				width:calc( ( 100% - 120px ) / 3 );
				margin:0px 20px;
			}
		}
	}
	@include sp(){
		margin:20px 0px;
	}
}

#top_apply{
	.description{
		@include pc(){
			margin:30px;
			font-size:16px;
		}
	}
}


#top_info{
	@include clearfix();
	@include pc(){
		margin:50px 0px;
		.top_news{
			width:580px;
			height:400px;
			box-sizing:border-box;
			position:relative;
			float:left;
			border:1px solid #D4D3D3;
			//background-color:#F4F3EE;
			.subtitle{
				//border-bottom:1px solid #D4D3D3;
				padding:10px 15px 5px;
				font-size:24px;
				font-weight:normal;
				background-color:#F4F3EE;
				border-radius:5px;
				margin:12px 10px 0px;
				&:before{
					display: inline-block;
					width: 5px;
					background-color: #AA4141;
					border-radius: 2px;
					content: "";
					height: 27px;
					vertical-align: top;
					margin-right: 7px;
					margin-top: 3px;
				}
			}
			ul{
				padding:10px 20px;
				li{
					border-bottom:1px dashed #D4D3D3;
					padding:20px 0px;
					@include clearfix();
					.date{
						font-size:16px;
						font-weight:bold;
						float:left;
						width:140px;
					}
					.text{
						overflow:hidden;
					}
				}
			}
			.more{
				text-align:right;
				position:absolute;
				right:20px;
				bottom:10px;
			}
		}
		.top_note{
			width:580px;
			height:400px;
			box-sizing:border-box;
			float:right;
			background:url(../images/top/bg_note.jpg) right bottom no-repeat #B8B8BA;
			color:#FFFFFF;
			.subtitle{
				border-bottom:1px solid #FFFFFF;
				padding:21px 20px 5px;
				font-size:24px;
				font-weight:normal;
				.en{
					font-size:20px;
					font-weight:bold;
					padding-left:20px;
				}
			}
			ul{
				padding:10px 20px;
				margin-top:40px;
				li{
					font-size:16px;
					margin-bottom:15px;
					a{
						color:#FFFFFF;
						background:url(../images/icon_right01.png) left center no-repeat;
						background-size:11px auto;
						padding-left:20px;
					}
				}
			}
		}
	}
	@include sp(){
		.top_news{
			margin:20px 0px;
			border:1px solid #D4D3D3;
			.subtitle{
				padding:10px 15px 5px;
				font-size:18px;
				font-weight:normal;
				background-color:#F4F3EE;
				border-radius:5px;
				margin:12px 10px 0px;
				&:before{
					display: inline-block;
					width: 5px;
					background-color: #AA4141;
					border-radius: 2px;
					content: "";
					height: 20px;
					vertical-align: top;
					margin-right: 7px;
					margin-top: 3px;
				}
			}
			ul{
				padding:10px 10px;
				li{
					border-bottom:1px dashed #D4D3D3;
					padding:10px;
					@include clearfix();
					.date{
						font-size:14px;
						font-weight:bold;
						margin-bottom:5px;
					}
					.text{
						overflow:hidden;
					}
				}
			}
			.more{
				text-align:right;
				padding:10px;
			}
		}
		.top_note{
			margin:20px 0px;
			background:url(../images/top/bg_note.jpg) right bottom no-repeat #B8B8BA;
			color:#FFFFFF;
			.subtitle{
				border-bottom:1px solid #FFFFFF;
				padding:10px 20px 5px;
				font-size:18px;
				font-weight:normal;
				.en{
					font-size:20px;
					font-weight:bold;
					padding-left:20px;
				}
			}
			ul{
				padding:10px 20px;
				margin-top:10px;
				li{
					font-size:14px;
					margin-bottom:10px;
					a{
						color:#FFFFFF;
						background:url(../images/icon_right01.png) left center no-repeat;
						background-size:11px auto;
						padding-left:20px;
					}
				}
			}
		}
	}
}


#top_bnner{
	@include pc(){
		margin:50px 0px;
	}
	.owl-item{
		text-align:center;
		>div{
		display:table-cell;
		height:60px;
		vertical-align:middle;
		}
		a{
			@include hover();
			padding:0px 10px;
			display:block;
			img{
				max-width: none;
				width: 100%;
			}
		}
	}
}

#top-slider{
	.slide-img-box{
		width: 100%;
		height: 560px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.top-slide-img1{
		background-image: url("../images/top/slider1.png");
	}
	.top-slide-img2{
		background-image: url("../images/top/slider2.png");
	}
	.top-slide-img3{
		background-image: url("../images/top/slider3.png");
	}
	.top-slide-img4{
		background-image: url("../images/top/slider4.png");
	}
	@include sp(){
		display:none;
	}
}


#sp_top_slider{
	@include pc(){
		display:none;
	}
}

#top_bnner{
	@include pc(){
		min-width:1170px;
	}
}


#top_blog{
	.blog_archive li{
		@include pc(){
			width: calc( ( 99% - 30px ) / 4);
		}
	}
	.more{
		text-align:right;
		margin:30px 0px;
	}
}


.top_news_box{
		width: 460px;
		margin: 20px 50px;
	@include sp(){
		width: 100%;
		margin: auto;
	}
}


.top_info_btn{
	display: inline-block;
	position: absolute;
	right: 0px;
	top: -120px;
	@include sp(){
		display: block;
		position: static;
		margin-top: 20px;
	}
}
.top_info_btn .btn_style06{
	box-shadow: 3px 3px 8px rgba(0,0,0,0.6);
	background-color: #FBA95E;
	border-color: #FBA95E;
	&:hover{
		background-color: #E89040;
		border-color: #E89040;
	}
	@include sp(){
		box-shadow: none;
	}
}

.top_info_text{
	display: inline-block;
	position: absolute;
	left: 0px;
	top: -160px;
	a{
		color: inherit;
		text-decoration: none;
	}
	@include sp(){
		display: block;
		position: static;
		margin-top: 20px;
	}
	.text01{
		background-color: #1F4B9F;
		color: #F8E81C;
		font-weight: bold;
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
		font-size: 20px;
		text-align: center;
		line-height: 1.2;
		padding: 10px;
	}
	.text02{
		color: #ffffff;
		background-color: #F4638A;
		font-weight: bold;
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
		font-size: 14px;
		text-align: center;
		line-height: 1.2;
		padding: 10px;
		.f_large{
			font-size: 145%;
		}
		a{
			cursor:text;
			@include sp(){
				cursor: pointer;
			}
		}
	}
}


