.inline_block{
	display:inline-block;
}
html{
	line-height:1.6;
}

.title_image{
	@media print {
		display:none ;
	}
}

@include sp(){
	.jump{
		color:#16AA89;
	}
}

/***　pankuzu　****/
.pankuzu{
	margin:20px 0px;
	@include clearfix();
	li{
		float:left;
		font-size:12px;
		&:after{
			content:"\f105";
			font-family: FontAwesome;
			padding:0px 10px;
		}
		&:nth-last-of-type(1){
			&:after{
				display:none;
			}
		}
	}
	@media print {
		display:none ;
	}
}


/*** editor ***/
.editor{
	a{
		color:#16AA89;
	}
	ul.list_style02 li{
		@include pc(){
			width:calc( 99% / 3 );
			margin-bottom:60px;
		}
	}

}



/*** list_style ***/
ul.list_style01{
	@include pc(){
		@include clearfix();
		margin-left:-43px;
		li{
			width:260px;
			float:left;
			margin-left:43px;
			.thumb{
				overflow:hidden;
				a{
					@include hover-scale();
				}
			}
			.text_wrap{
				border:1px solid #ECEAEA;
				padding:25px 30px;
				.title{
					font-size:20px;
					font-weight:normal;
					margin-bottom:20px;
				}
				.text{
					font-size:12px;
					color:#666666;
					margin-bottom:20px;
				}
				.more{
					a{
						color:#51B39E;
						font-size:14px;
						font-weight:bold;
					}
				}
			}
		}
	}
	@include sp(){
		border-top:1px solid #ECEAEA;
		li{
			padding:5px 0px;
			border-bottom:1px solid #ECEAEA;
			.thumb{
				float:left;
				width:120px;
				margin-right:10px;
				margin-top:5px;
			}
			.text_wrap{
				overflow:hidden;
				.title{
					font-size:16px;
					font-weight:bold;
					margin-bottom:5px;
				}
				.text{
					font-size:12px;
					color:#666666;
					margin-bottom:5px;
				}
				.more{
					a{
						color:#51B39E;
						font-size:12px;
						font-weight:bold;
					}
				}
			}
		}
	}
}


	
ul.list_style02{
	@include pc(){
		@include clearfix();
		li{
			width:25%;
			float:left;
			margin-bottom:40px;
			a{
				color:#222222;
				@include hover();
				&:hover{
					text-decoration:none;
				}
			}
			.thumb{
				text-align:center;
			}
			.text_wrap{
				font-size:16px;
				margin-top:10px;
				text-align:center;
			}
		}
	}
	@include sp(){
		border-top:1px solid #DDDDDD;
		//margin-left:-10px;
		//margin-right:-10px;
		li{
			border-bottom:1px solid #DDDDDD;
			background: -moz-linear-gradient(top,#FFF 0%,#EEE);
			background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
			//padding:0px 10px;
			a{
				display:table;
				width:100%;
				text-decoration:none;
				color:#222222;
				.thumb{
					display:table-cell;
					vertical-align:middle;
					width:50px;
					padding:10px;
				}
				.text_wrap{
					font-size:16px;
					display:table-cell;
					vertical-align:middle;
					text-shadow: 1px 1px 0px rgb(255, 255, 255);
				}
			}
		}
	}
}


ul.list_style03{
	@include pc(){
		li{
			//padding-left:15px;
			padding:6px 15px;
			a{
				//padding-bottom:12px;
				//display:inline-block;
				&:hover,
				&.cur{
					color:#51B39E;
				}
			}
			&.child{
				padding-left:30px;
			}
		}
	}
	@include sp(){
		border-top:1px solid #DDDDDD;
		//margin-left:-10px;
		//margin-right:-10px;
		li{
			border-bottom:1px solid #DDDDDD;
			background: -moz-linear-gradient(top,#FFF 0%,#EEE);
			background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
			//padding:0px 10px;
			a{
				display:block;
				padding:10px;
				text-decoration:none;
				text-shadow: 1px 1px 0px rgb(255, 255, 255);
				&.cur{
					color:#51B39E;
				}
			}
			&.child{
				a{
					padding-left:20px;
					&:before{
						content:"\f178";
						font-family: FontAwesome;
						//color:#51B39E;
						padding-right:5px;
					}
				}
			}
		}
	}
}

.list_style_decimal{
	list-style-type: decimal;
	margin-left: 20px;
	li{
		margin-bottom: 10px;
	}
}

.list_style_circle{
	list-style-type: circle;
	margin-left: 20px;
	li{
		margin-bottom: 10px;
	}
}



/*** btn_style ***/
a.btn_style01{
	display:block;
	color:#51B39E;
	font-weight:bold;
	padding:15px 0px;
	text-align:center;
	text-decoration:none;
	border:1px solid #51B39E;
	border-radius:8px;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		&:hover{
			background-color:#51B39E;
			color:#FFFFFF;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
	}
}

a.btn_style02{
	border:1px solid #666666;
	text-decoration:none;
	background-color:#FFFFFF;
	text-align:center;
	color:#292929 !important;
	text-decoration:none;
	@include pc(){
		display:inline-block;
		padding:5px 20px;
		transition: 0.3s;
		&:hover{
			background-color:#666666;
			color:#FFFFFF !important;
		}
	}
	@include sp(){
		display:block;
		padding:10px;
	}
}

a.btn_style03{
	display:inline-block;
	border:1px solid #51B39E;
	padding:2px 30px;
	text-decoration:none;
	background-color:#51B39E;
	color:#FFFFFF !important;
	text-align:center;
	border-radius:5px;
	@include sp(){
		border-radius:5px;
		display:block;
		padding:2px;
	}
	@include pc(){
		transition: 0.3s;
		&:hover{
			background-color:transparent;
			color:#51B39E !important;
		}
	}
    
    &.orange{
        background-color: #fba95e;
        border: #fba95e 1px solid;
		border-radius:5px;
        
        transition: 0.3s;
		&:hover{
			background-color:transparent;
			color:#fba95e !important;
		}
    }
}

a.btn_style04{
	display:block;
	color:#FFFFFF;
	font-weight:bold;
	padding:15px 0px;
	text-align:center;
	text-decoration:none;
	border:1px solid #fba95e;
	border-radius:8px;
	background-color:#fba95e;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		&:hover{
			background-color:#ff983b;
			color:#FFFFFF;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
	}
}

a.btn_style05{
	display:inline-block;
	border:1px solid #51B39E;
	padding:2px 30px;
	text-decoration:none;
	background-color:transparent;
	color:#51B39E !important;
	text-align:center;
	border-radius:5px;
	@include sp(){
		display:block;
		padding:2px;
	}
	@include pc(){
		transition: 0.3s;
		&:hover{
			background-color:#51B39E;
			color:#FFFFFF !important;
		}
	}
}

a.btn_style06{
	display:block;
	color:#FFFFFF;
	font-weight:bold;
	padding:10px;
	text-align:center;
	text-decoration:none;
	border:1px solid #51B39E;
	border-radius:8px;
	background-color:#51B39E;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		&:hover{
			background-color:#00816F;
			color:#FFFFFF;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
	}
}

a.btn_style07{
	display:block;
	color:#51B39E;
	font-weight:bold;
	padding:10px;
	text-align:center;
	text-decoration:none;
	border:1px solid #51B39E;
	border-radius:8px;
	background-color:#FFFFFF;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		&:hover{
			background-color:#00816F;
			color:#FFFFFF;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
	}
}


a.btn_submit,
input.btn_submit{
	display:inline-block;
	font-weight:bold;
	padding:15px;
	text-align:center;
	text-decoration:none;
	border:1px solid #51B39E;
	border-radius:8px;
	background-color:#51B39E;
	color:#FFFFFF;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		min-width:300px;
		@include hover();
		&:hover{
			background-color:#00816F;
			cursor:pointer;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
		display:block;
		width:100%;
		box-sizing:border-box;
	}
}

a.btn_back,
input.btn_back{
	display:inline-block;
	font-weight:bold;
	padding:15px;
	text-align:center;
	text-decoration:none;
	border:1px solid #51B39E;
	border-radius:8px;
	background-color:#FFFFFF;
	color:#51B39E;
	@include pc(){
		font-size:18px;
		transition: 0.3s;
		min-width:300px;
		@include hover();
		&:hover{
			background-color:#51B39E;
			color:#FFFFFF;
			cursor:pointer;
		}
	}
	@include sp(){
		box-shadow:1px 2px 2px rgba(0,0,0,0.10);
		margin-bottom:15px;
		font-size:16px;
		display:block;
	}
}

.btn_wrap{
	margin:30px 0px;
	text-align:center;
}





/*** title_stylet ***/
.title_style01{
	background-color:#4A4A4A;
	color:#FFFFFF;
	font-size:20px;
	font-weight:normal;
	padding:10px 20px;
	margin-bottom:30px;
	@include sp(){
		margin-bottom:10px;
		font-size:18px;
	}
}

.title_style02{
	background-color:#F2F2F2;
	font-size:18px;
	padding:10px 15px;
	margin-bottom:15px;
}

.title_style03{
	border-top:8px solid #51B39E;
	color:#51B39E;
	font-size:24px;
	font-weight:normal;
	padding:5px 10px 50px;
	@include sp(){
		border-top:5px solid #51B39E;
		font-size:20px;
		padding:5px 10px 20px;
	}
}

.title_style04{
	color:#16AA89;
	font-size:20px;
	font-weight:bold;
	border-bottom:1px solid #16AA89;
	margin-top:50px;
	margin-bottom:30px;
	@include sp(){
		font-size:18px;
		margin-top:15px;
		margin-bottom:20px;
	}
}

.title_style05{
	color:#181210;
	font-size:18px;
	font-weight:bold;
	margin-bottom:5px;
	margin-top:30px;
}

.title_style06{
	color:#16AA89;
	font-size:18px;
	font-weight:bold;
	margin-top:30px;
	margin-bottom:10px;
	@include sp(){
		font-size:16px;
		margin-top:20px;
		margin-bottom:10px;
	}
}


.description{
	@include pc(){
		margin:30px;
		font-size:16px;
	}
	@include sp(){
		margin:10px;
		font-size:14px;
	}
}


/*** box_style ***/
.box_style01{
	border:1px solid #D0021B;
	background-color:#FDFAD2;
	padding:10px 30px;
	color:#D0021B;
	line-height:1.8;
	margin-top:15px;
}

.box_style02{
	padding: 20px;
	border: 1px solid #CCC;
	background:url(../images/bg_box_style02.gif) left top repeat;
	margin-top:15px;
}

.box_style03{
	padding:15px 20px;
	border: 2px dashed #16AA89;
	margin-top:15px;
}

.box_style04{
	padding:15px 20px;
	border: 6px solid #FF8000;
	margin-top:15px;
}



/*** table_style ***/
.table_style01{
	width:100%;
		border-left:1px solid #979797;
		border-top:1px solid #979797;
	th{
		width:200px;
		padding:20px 10px;
		box-sizing:border-box;
		vertical-align:top;
		border-right:1px solid #979797;
		border-bottom:1px solid #979797;
		font-size:16px;
		font-weight:normal;
		background-color:#F2F2F2;
		@include sp(){
			display:block;
			width:100%;
			padding:10px;
		}
}
	td{
		padding:20px 10px;
		vertical-align:top;
		border-right:1px solid #979797;
		border-bottom:1px solid #979797;
		@include sp(){
			display:block;
			padding:10px;
		}
	}
}

.table_style02{
	width:100%;
	background:url(../images/bg_table_style02.jpg) left top repeat;
	line-height:26px;
	th{
		width:210px;
	}
	td{
	}
}

.table_style03{
	margin-top: 70px;
	width: 100%;
	border-top: 1px solid #EEEEEE;
	th{
		padding: 20px 0px;
		vertical-align: middle;
		font-size: 16px;
		font-weight: normal;
		color: #333333;
		border-bottom: 1px solid #EEEEEE;
		width:190px;
		@include sp(){
			display:block;
			width:100%;
			padding:10px;
			box-sizing:border-box;
		}
	}
	td{
		padding: 20px 0px;
		vertical-align: middle;
		font-size: 16px;
		font-weight: normal;
		color: #333333;
		border-bottom: 1px solid #EEEEEE;
		@include sp(){
			display:block;
			width:100%;
			padding:10px;
			box-sizing:border-box;
		}
	}
}

.table_style04{
	margin-top:20px;
	width: 100%;
	border-top: 1px solid #DDDDDD;
	border-left: 1px solid #DDDDDD;
	@include sp(){
		margin-bottom:30px;
	}
	th{
		padding:10px 20px;
		vertical-align: middle;
		font-size: 14px;
		font-weight: normal;
		color: #333333;
		border-right: 1px solid #DDDDDD;
		@include pc(){
			border-bottom: 1px solid #DDDDDD;
			width:240px;
		}
		@include sp(){
			display:block;
		}
	}
	td{
		padding:10px 20px;
		vertical-align: middle;
		font-size: 14px;
		font-weight: normal;
		color: #333333;
		border-right: 1px solid #DDDDDD;
		border-bottom: 1px solid #DDDDDD;
		@include sp(){
			display:block;
		}
	}
}





/** text ***/
.head_num{
	position:relative;
	margin-left:1.5em;
	.num{
		position:absolute;
		left:-1.5em;
	}
}


/**** form ****/
select{
	font-size:16px;
	padding:5px 10px;
}


    .tel_num{
        padding: 20px;
        border: 1px solid #CCCCCC;
        background: efefef;
        
        p{
            line-height: 1;
        }
        
        p.tel{
            font-size: 32px;
            font-weight: bold;
            color:#51B39E;
            margin-top: 10px;
            border-top: 1px solid #ccc;
            padding-top: 20px;
            
        }
    }

