footer{
	@media print {
		display:none;
	}
	@include pc(){
		min-width:1170px;
	}
}

.footer_main{
	background-color:#444444;
	padding:40px 0px;
	.footer_list{
		@include clearfix();
		margin-left:-40px;
		@include sp(){
			display:none;
		}
		> li{
			float:left;
			margin-left:40px;
			margin-bottom:40px;
			> a{
				color:#FFFFFF;
				font-size:16px;
				&:after{
					content:"";
					display:block;
					width:50px;
					height:5px;
					background-color:#BCA882;
					margin-top:10px;
				}
			}
			ul.sub-menu{
				margin-top:25px;
				li{
					a{
						color:#AAAAAA;
						font-size:14px;
					}
				}
			}
		}
	}
	.footer_link_wrap{
		@include pc(){
			text-align:right;
			.footer_link{
				@include clearfix();
				display:inline-block;
				li{
					float:left;
					margin-left:50px;
					a{
						color:#FFFFFF;
					}
				}
			}
		}
		@include sp(){
			.footer_link{
				li{
					margin-bottom:5px;
					a{
						color:#FFFFFF;
						&:before{
						content:"\f0a9";
						font-family: FontAwesome;
						color:#51B39E;
						padding-right:5px;
						}
					}
				}
			}
		}
	}
}

.footer_bottom{
	background-color:#555555;
	.copyright{
		color:#747474;
		font-size:10px;
		padding:20px 0px;
	}
}

.pagetop{
	position:fixed;
	right:40px;
	bottom:-10px;
	z-index:5000;
	opacity: 0;
	transition: 0.5s;
	@include sp(){
		right:20px;
	}
	&.show {
		bottom:40px;
		opacity:0.8;
		@include sp(){
			bottom:20px;
		}
	}
	@media print {
		display:none;
	}
}