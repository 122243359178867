#wrapper.shop{
	.title_image{
		@include pc(){
			background:url(../images/shop/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
}


.gmap_iflame{
	width:100%;
	height:260px;
	margin-top:30px
}

.center_box{
    @include clearfix();
    
    .fl_box{
        width:50%;
        float: left;
        @include sp{
            width: 100%;
        }
    }
}

.add{
	.tel{
		display:inline-block;
		width:160px;
	}
	.fax{
		display:inline-block;
		margin-left:160px;
	}
}
