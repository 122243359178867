
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
html {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	line-height: 1.5;
	color: #222222;
	overflow-y: scroll;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
input, textarea {
	margin: 0;
	padding: 0;
}
ol, ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th {
	text-align: left;
}
a {
	color: #292929;
	text-decoration: none;
}
a:hover {
	color: #292929;
	text-decoration:underline;
}
a:focus {
	outline: none;
}
img {
	vertical-align: bottom;
}
img, object, embed, video {
	max-width: 100%;
	height: auto;
}

@media all and (-ms-high-contrast:none) {
 *::-ms-backdrop, img {
table-layout: fixed;
} /* IE11 */
}
.ie6 img {
	width: 100%;
}
