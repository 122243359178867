body#shoppingloan{
	.main_content{
		@include sp(){
			display:none;
		}
	}
}

#wrapper.shoppingloan{
	.title_image{
		@include pc(){
			background:url(../images/shoppingloan/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
}


/*** recruit ****/
.recruit_img{
	float:right;
	margin:0px 0px 10px 20px;
	@include sp(){
		width:120px;
	}
}
.recruit_box01{
	@include clearfix();
	margin-bottom:30px;
	@include pc(){
		.left{
			width:350px;
			float:left;
		}
		.right{
			width:400px;
			float:right;
		}
	}
	dl{
		display:table;
		width:100%;
		background-color:#FFFCD1;
		padding:10px;
		border:3px solid #16AA89;
		margin-bottom:8px;
		box-sizing:border-box;
		
		dt{
			display:table-cell;
			width:60%
		}
		dd{
			display:table-cell;
			width:40%;
			color:#D0021B;
			font-weight:bold;
		}
	}
}
.recruit_box02{
	@include clearfix();
	.box01{
		padding-bottom:20px;
		h3{
			color: #16AA89;
			font-size: 20px;
			font-weight: bold;
			text-align:center;
			margin:20px 0px;
		}
		@include pc(){
			width:370px;
			float:left;
		}
		ul{
			li{
				background-color:#16AA89;
				color:#FFFFFF;
				text-align:center;
				padding:10px 0px;
				margin-bottom:30px;
				position:relative;
				&:after{
					content:"";
					display:block;
					width: 0;
					height: 0;
					border-top: 20px solid #16AA89;
					border-right: 185px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 185px solid transparent;
					position:absolute;
					left:50%;
					bottom:-40px;
					margin-left:-185px;
					@include sp(){
						border-right: 160px solid transparent;
						border-left: 160px solid transparent;
						margin-left:-160px;
					}
				}
				&.last{
					background-color:#00816F;
					padding:20px 0px;
					&:after{
						//border-top: 20px solid #00816F;
						border-top:none;
					}
				}
			}
		}
	}
	.box02{
		padding-bottom:20px;
		h3{
			color: #FE913C;
			font-size: 20px;
			font-weight: bold;
			text-align:center;
			margin:20px 0px;
		}
		@include pc(){
			width:370px;
			float:right;
		}
		ul{
			li{
				background-color:#FE913C;
				color:#FFFFFF;
				text-align:center;
				padding:10px 0px;
				margin-bottom:30px;
				position:relative;
				&:after{
					content:"";
					display:block;
					width: 0;
					height: 0;
					border-top: 20px solid #FE913C;
					border-right: 185px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 185px solid transparent;
					position:absolute;
					left:50%;
					bottom:-40px;
					margin-left:-185px;
					@include sp(){
						border-right: 160px solid transparent;
						border-left: 160px solid transparent;
						margin-left:-160px;
					}
				}
				&.last{
					background-color:#E37F34;
					padding:20px 0px;
					&:after{
						//border-top: 20px solid #E37F34;
						border-top:none;
					}
				}
			}
		}
	}
    
    .center_box{
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
        img{
            width: 406px;
        }
    }
    
    
}
.recruit_flow_area_list{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #CCC;
    
    @include clearfix;
    
    li{
        width:50%;
        float: left;
        
        margin-bottom: 10px;
        
        @include sp{
            width: 100%;
            float: none;
        }
    }
}
.recruit_attention{
    font-size: 11px;
    color:#BB282A;
}
.recruit_flow{
	@include pc(){
		@include clearfix();
		width:100%;
		li{
			float:left;
			width:calc( ( 100% - 100px ) / 6 );
			height:120px;
			vertical-align:top;
			background-color:#16AA89;
			color:#FFFFFF;
			padding:25px 0px 25px 10px;
			box-sizing:border-box;
			margin-right:20px;
			position:relative;
			&:after{
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-top: 60px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 60px solid transparent;
				border-left: 20px solid #16AA89;
				position: absolute;
				right: -40px;
				top: 0px;
				@include sp(){
					border-right: 160px solid transparent;
					border-left: 160px solid transparent;
					margin-left:-160px;
				}
			}
			&.last{
				margin-right:0px;
			}
			.num{
				display:block;
			}
		}
	}
	@include sp(){
		li{
			background-color:#16AA89;
			color:#FFFFFF;
			padding:10px;
			margin-bottom:30px;
			position:relative;
			&:after{
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-top: 20px solid #16AA89;
				border-right: 30px solid transparent;
				border-bottom: 20px solid transparent;
				border-left: 30px solid transparent;
				position: absolute;
				left: 50%;
				bottom: -45px;
				margin-left: -30px;
			}
			&.last{
				&:after{
					display:none;
				}
			}
		}
	}
}


.recruit_list{
	background-color: #FF8000;
	padding: 20px;
	color: #ffffff;
	font-size: 18px;
	margin-top: 20px;
	@include clearfix();
	li{
		@include pc(){
			float: left;
			margin-right: 20px;
		}
	}
}





/*** lineup_box ****/
.lineup_box{
	@include pc(){
		@include clearfix();
		.thumb{
			width:260px;
			float:left;
			margin-right:25px;
		}
		.text_wrap{
			overflow:hidden;
			height:173px;
			position:relative;
			.more{
				position:absolute;
				left:0px;
				bottom:0px;
			}
		}
	}
	@include sp(){
		.thumb{
			margin-bottom:15px;
			img{
				width:100%;
			}
		}
		.text_wrap{
			.more{
				margin-top:15px;
			}
		}
	}
}


.table_style01{
	&.general_table{
		th{
			background-color:#F7D5E5;
		}
	}
	&.medical_table{
		th{
			background-color:#F8E3C0;
		}
	}
	&.requirement_table{
		th{
			background-color:#DFF9F6;
		}
	}
}



/*** merit ***/
#merit{
	@include pc(){
		.main_content{
			position:relative;
		}
		.merit_wrap{
			position:absolute;
			width:640px;
			left:65px;
			top:108px;
			.merit_box{
				box-sizing:border-box;
				padding:30px 40px;
				background-color:rgba(255,255,255,0.80);
				border-radius:10px;
				margin-bottom:5px;
				.title_style04{
					margin-bottom:15px;
					margin-top:0px;
				}
				p{
					font-size:16px;
					line-height:1.75;
				}
			}
		}
	}
	@include sp(){
		.merit_box{
			margin-top:30px;
		}
	}
}


/*** service ***/
.service_intro{
	margin-top:30px;
	display:table;
	width:100%;
	@include sp(){
		display:block;
	}
	.intro_left{
		display:table-cell;
		width:380px;
		@include sp(){
			display:block;
			width:100%;
		}
		dt{
			background-color:#00B69C;
			color:#FFFFFF;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			line-height: 32px;
		}
		dd{
			border-right: 2px solid #00B69C;
			border-bottom: 2px solid #00B69C;
			border-left: 2px solid #00B69C;
			padding: 25px;
		}
	}
	.intro_center{
		display:table-cell;
		vertical-align:middle;
		text-align:center;
		font-size:60px;
		color:#00B69C;
		@include sp(){
			display:block;
			width:100%;
			transform: rotate(90deg);
		}
	}
	.intro_right{
		display:table-cell;
		background-color:#00B69C;
		width: 340px;
		vertical-align: middle;
		@include sp(){
			display:block;
			width:100%;
		}
		p{
			padding:30px;
			color: #FFFFFF;
			font-size: 18px;
			font-weight: bold;
		}
	}
}




/*** attention ***/
.attention_nav ul{
	@include clearfix();
	li{
		float:left;
		width:calc( 99% / 3 );
		a{
			display:block;
			margin-right:5px;
			height:100%;
			box-sizing:border-box;
		}
	}
}


/*** career ***/
.career_box{
	@include clearfix();
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	padding:15px 0px;
	margin:20px 0px;
	.left{
		@include pc(){
			width:360px;
			float:left;
			margin-right:20px;
		}
		@include sp(){
			width:auto;
			float:none;
			margin:0px 0px 20px;
		}
	}
	.right{
		overflow:hidden;
		dt{
			border-bottom: 1px solid #f0f0f0;
			padding-bottom: 5px;
			margin-bottom: 10px;
			font-size: 16px;
		}
		dd{
			ul{
				@include clearfix();
				li{
					float:left;
					background-color:#F8F8F8;
					padding:2px 10px;
					margin-right:5px;
					margin-bottom:5px;
					font-size:12px;
				}
			}
		}
	}
}


/*** contact ****/
.contact_box{
	@include clearfix();
	.left{
		@include pc(){
			width:350px;
			float:left;
		}
		@include sp(){
			margin-bottom:20px;
		}
	}
	.right{
		@include pc(){
			width:400px;
			float:right;
		}
		@include sp(){
			margin-bottom:20px;
		}
	}
}


/*** form ***/
.form{
	input[type="text"],
	textarea{
		font-size:20px;
		line-height:1.5;
		padding:10px 5px;
		border:1px solid #AAAAAA;
		-moz-border-radius:5px;
		-ms-border-radius:5px;
		-o-border-radius:5px;
		-webkit-order-radius:5px;
		border-radius:5px;
		box-sizing:border-box;
	}
	input.type_01{
		width:410px;
		@include sp(){
			width:100%;
			box-sizing:border-box;
		}
	}
	input.type_02{
		width:300px;
		@include sp(){
			width:100%;
			box-sizing:border-box;
		}
	}
	input.type_03{
		//width:600px;
		width:100%;
	}
	input.type_04{
		width:140px;
	}
	input.type_05{
		width:80px;
	}
	textarea{
		//width:600px;
		width:100%;
		height:200px;
	}
	.checkbox_text{
		line-height:22px;
		position:relative;
		//margin-left:190px;
		//width:710px;
		font-size:16px;
		font-weight:normal;
	}
	.checkbox_text span{
		display:inline-block;
		width:640px;
		@include sp(){
			width:100%;
			box-sizing:border-box;
		}
	}
	.checkbox{
		position:absolute;
		top:0px;
		left:0px;
		filter:alpha(opacity=0);
		-ms-filter:"alpha(opacity=0)";
		-moz-opacity:0;
		-khtml-opacity:0;   
		opacity:0;
		width:100%; 
		height:100%;
	}
	.checkbox + label{
		display:inline-block;
		*display:inline;
		*zoom:1;
		background:url(/shared/images/shoppingloan/form/icon_checkbox_off.png) left top no-repeat;
		width:27px;
		height:22px;
		vertical-align:top;
	}
	label.on{
		background:url(/shared/images/shoppingloan/form/icon_checkbox_on.png) left top no-repeat;
	} 
	.checkbox_text a{
		position:relative;
		z-index:10;
	}
	.checkbox_list{
		overflow:hidden;
	}
	.checkbox_list li{
		float:left;
		margin-right:20px;
		position:relative;
		padding: 10px 0px;
		&.other_wrap{
			margin-top: -8px;
			margin-left: -8px;
			padding-bottom: 0px;
			input[type="text"]{
				padding: 5px;
				width: 260px;
				font-size: 18px;
			}
			@include sp(){
				margin: 0px;
				width:100%;
				input[type="text"]{
					width:100%;
				}
			}
		}
	}
	.agree_wrap{
		//display: table;
		width: 100%;
		overflow: hidden;
		.agree_checkbox{
			width: 35px;
			position: relative;
			float: left;
		}
		.agree_text{
			overflow: hidden;
			display: block;
		}
	}
}


	

