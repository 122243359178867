#wrapper.career{
	.title_image{
		@include pc(){
			background:url(../images/career/title_image.png) center top no-repeat;
			background-size:auto 140px;
			height:140px;
			width:100%;
		}
		@include sp(){
			display:none;
		}
	
	}
    
    .tel_num{
        padding: 20px;
        border: 1px solid #CCCCCC;
        background: efefef;
        
        p{
            line-height: 1;
        }
        
        p.tel{
            font-size: 32px;
            font-weight: bold;
            color:#51B39E;
            margin-top: 10px;
            border-top: 1px solid #ccc;
            padding-top: 20px;
            
        }
    }
}

