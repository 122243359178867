header{
	@media print {
		display:none;
	}
	@include pc(){
		min-width:1170px;
	}
}

#header_top{
	background-color:#51B39E;
	.seo_title{
		font-size:12px;
		font-weight:normal;
		color:#FFFFFF;
		padding:6px 0px;
	}
}
#header_loggin{
	@include pc(){
		background-color:#F4F3EE;
		position:relative;
		z-index:100;
		.container{
			position:relative;
			padding:7px 0px;
			height:30px;
		}
		.logo{
			position:absolute;
			left:0px;
			top:10px;
			z-index:10;
			a{
				text-align:center;
				text-decoration:none;
				.catch{
					//color:#FFFFFF;
				}
				.name{
					font-size: 16px;
					font-weight: bold;
					margin-top: 2px;
				}
			}
		}

		.loggin_box{
			text-align:right;
			vertical-align:middle;
			img.icon_loggin{
				padding-left:10px;
				vertical-align:middle;
			}
			a{
				margin-left:10px;
			}
		}
		.btn_menu_open{
			display:none;
		}
		.contact_list{
			text-align:right;
			margin-top:10px;
		}
	}
	@include sp(){
		.loggin_box{
			display:none;
		}
		.logo{
			text-align:center;
			padding-top:10px;
			a{
				text-decoration:none;
				.catch{
					font-size:11px;
					margin:5px 0px 3px
				}
				.img{
					margin:0px 50px;
				}
				.name{
					font-size:18px;
					font-weight:bold;
					margin:3px 0px 5px;
				}
			}
		}
		.btn_menu_open{
			position:absolute;
			left:0px;
			top:50%;
			margin-top:-40px;
			text-align:center;
			a{
				font-size:40px;
				line-height:1;
				span{
					font-size:12px;
					display:block;
				}
			}
		}
	}
}
#header_main{
	@include pc(){
		background-color:#F4F3EE;
		.container{
			position:relative;
			padding:10px 0px;
			height:70px;
			.btn_menu_open{
				display:none;
			}
			.gnav{
				position:absolute;
				right:0px;
				bottom:20px;
				.sp_loggin_box{
					display:none;
				}
				ul{
					@include clearfix();
					>li{
						float:left;
						margin-left:20px;
						position:relative;
						a{
							border-bottom:1px solid rgba(255,255,255,0.00);
							padding-bottom:10px;
							&:hover{
								border-bottom:1px solid #51B39E;
								text-decoration:none;
							}
						}
						&.current,
						&.current_page_item{
							a{
								border-bottom:1px solid #51B39E;
								text-decoration:none;
							}
						}
						>ul.sub-menu{
							//display:none;
							z-index:100;
							position:absolute;
							background: rgba(0, 0, 0, 0.8);
							box-shadow: 0 10px 20px 0px rgba(0,0,0,0.3);
							border-radius: 4px;
							padding:20px;
							visibility: hidden;
							transition: .5s;
							opacity: 0;
							transform: translateY(20px);
							width:260px;
							right:0px;
							&.open{
								transform: translateY(0px);
								opacity: 1;
								visibility: visible;
							}

							li{
								border-bottom:1px solid rgba(255,255,255,0.15);
								float:none;
								margin:0px;
								a{
									color:#FFFFFF;
									padding:10px 15px;
									display: block;
									text-decoration: none;
									border-bottom:1px solid rgba(255,255,255,0.00);
									&:before{
										content:"\f0a9";
										font-family: FontAwesome;
										color:#51B39E;
										padding-right:5px;
									}
									&:hover{
										border-bottom:1px solid #51B39E;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@include sp(){
		display:none;
	}
}


.sp_gnav_wrap{
	@include pc(){
		display:none;
	}
	@include sp(){
		position: fixed;
		left: 10px;
		top: 0px;
		z-index: 999;
		width:240px;
		visibility: hidden;
		transition: .5s;
		opacity: 0;
		transform: translateY(20px);
		&.open{
			transform: translateY(0px);
			opacity: 1;
			visibility: visible;
		}
		.btn_menu_close{
			a{
				font-size:40px;
				line-height:1;
				background-color: RGBA(255, 255, 255, 0.70);
				padding: 0px 5px;
				border-radius: 5px;
				display: inline-block;
			}
		}
		.gnav{
			//visibility: hidden;
			background: rgba(0, 0, 0, 0.8);
			box-shadow: 0 10px 20px 0px rgba(0,0,0,0.3);
			border-radius: 4px;
			overflow-y: scroll;
			.sp_loggin_box{
				padding:20px 10px;
			}
			ul{
				border-top:1px solid rgba(255,255,255,0.15);
				margin-bottom:10px;
				li{
					border-bottom:1px solid rgba(255,255,255,0.15);
					a{
						color:#FFFFFF;
						padding:10px 15px;
						display: block;
						text-decoration: none;
						&:before{
							content:"\f0a9";
							font-family: FontAwesome;
							color:#51B39E;
							padding-right:5px;
						}
					}
					ul.sub-menu{
						display:none;
					}
				}
			}
		}
	}
}


#loggin_wrap{
	@include pc(){
		padding:20px 50px;
		.loggin_box{
			width:460px;
		}
	}
	@include sp(){
		padding:0px;
		.loggin_box{
			width:100%;
		}
	}
	#id{
		ime-mode:disabled;
	}
	input[type="text"],
	input[type="password"]{
		width:100%;
		font-size:16px;
		padding:10px 15px;
		border-radius:7px;
		border:1px solid #DDDDDD;
		box-sizing:border-box;
	}
	input[type="submit"]{
		width:100%;
		padding:10px;
		box-sizing:border-box;
	}
	.loggin_title{
		@include pc(){
			font-size:28px;
			color:#51B39E;
			margin-bottom:20px;
			img{
				padding-right:20px;
			}
		}
		@include sp(){
			font-size:20px;
			color:#51B39E;
			margin-bottom:10px;
			text-align:center;
			img{
				display:block;
				margin:0px auto 10px;
			}
		}
	}
	.loggin_id{
		margin-bottom:10px;
	}
	.loggin_pass{
		margin-bottom:30px;
		@include sp(){
			margin-bottom:15px;
		}
	}
	.text{
		text-align:right;
		margin-bottom:30px;
		@include sp(){
			text-align:center;
			margin-bottom:15px;
		}
		a{
			color:#054590;
		}
	}
}

.fancybox-overlay{
	background:rgba(255,255,255,0.6);
}
.fancybox-skin{
	background-color:rgba(255,255,255,0.85);
	border-radius:10px;
	box-shadow:4px 6px 31px rgba(0,0,0,0.50);
}


.loggin_submit{
	input.btn_submit{
		display:inline-block;
		font-weight:bold;
		padding:15px;
		text-align:center;
		text-decoration:none;
		border:1px solid #00816F;
		border-radius:8px;
		
		background-color:#00816F;
		color:#FFFFFF;
		@include pc(){
			font-size:18px;
			transition: 0.3s;
			min-width:300px;
			@include hover();
			&:hover{
				border:1px solid #0D5F53;
				background-color:#0D5F53;
				cursor:pointer;
			}
		}
		@include sp(){
			box-shadow:1px 2px 2px rgba(0,0,0,0.10);
			margin-bottom:15px;
			font-size:16px;
			display:block;
			width:100%;
			box-sizing:border-box;
		}
	}

}